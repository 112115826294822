<template>
  <main-layout pageTitle="Leagues">
    <h3 class="ion-padding-horizontal mt-3">Please select a league</h3>
    <leagues-list />

    <div class="mt-3 mx-3">
      <news />
    </div>
  </main-layout>
</template>

<script>
import News from "@/components/News.vue";
import LeaguesList from "../components/leagues/LeaguesList.vue";
export default {
  components: { LeaguesList, News },
};
</script>

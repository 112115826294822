<template>
  <div v-if="leagues">
    <ion-list>
      <ion-item
        v-for="league of leagues.data"
        :key="league.id"
        button
        @click="$router.push('/league/' + league.id + '/home')"
      >
        <ion-label>{{ league.name }}</ion-label>
        <ion-badge>{{ league.users_count }}</ion-badge>
      </ion-item>
    </ion-list>
    <!-- <ion-infinite-scroll
      @ionInfinite="loadData($event)"
      threshold="100px"
      id="infinite-scroll"
      :disabled="isDisabled"
    >
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        loading-text="Loading more data..."
      >
      </ion-infinite-scroll-content>
    </ion-infinite-scroll> -->
  </div>
  <p v-else>Loading....</p>
</template>

<script>
import leaguesModule from "../../modules/leagues";
const { load, leagues } = leaguesModule;

import { IonItem, IonList, IonLabel, IonBadge } from "@ionic/vue";

export default {
  data: () => ({
    leagues: null,
  }),
  components: {
    IonList,
    IonItem,
    IonLabel,
    IonBadge,
  },
  methods: {
    loadData($event) {
      console.log($event);
      // load()
    },
  },
  mounted() {
    this.leagues = leagues;
    load();

    leaguesModule.setCurrentLeague(null);
  },
};
</script>
